import { vega } from 'vega-embed';
import isFunction from './isFunction';
export default function updateSingleDatasetInView(view, name, value) {
  if (value) {
    if (isFunction(value)) {
      value(view.data(name));
    } else {
      view.change(name, vega.changeset().remove(() => true).insert(value));
    }
  }
}